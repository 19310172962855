import Text from '@deprecated-ui/common/Text';
import Flex from '@deprecated-ui/common/Flex';
import device from '@deprecated-ui/_style/device';
import color from '@deprecated-ui/_style/color';
import styled from 'styled-components';
import Image from 'global/Image';
import { SectionTitle, SectionSubtitle, Wrapper } from '../styled';
import { fadeInDown } from '../keyframes';

const HeaderWrapper = styled(Wrapper)`
  margin-top: 70px;

  @media ${device.laptop} {
    align-items: center;
    flex-direction: row;
    padding: 50px 0;
    height: calc(915px + 100px);
  }
`;

const TitleWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  align-items: center;
  width: 100%;

  @media ${device.laptop} {
    align-items: flex-start;
    padding-right: 50px;
    width: 60%;
    position: relative;
    z-index: 1;
  }
`;

const Title = styled(SectionTitle)`
  padding-top: 90px;
  margin: auto;

  @media ${device.laptop} {
    font-size: 48px;
    margin: unset;
    padding-top: unset;
    text-align: left;
  }
`;

const SubtitleWrapper = styled(Flex).attrs({
  align: 'center',
  direction: 'column',
})`
  height: 89px;
  margin: auto;
  position: relative;

  @media ${device.laptop} {
    margin: unset;
  }
`;

const AnimatedSubtitle = styled(Text).attrs({
  family: 'bold',
})`
  font-size: 42px;
  opacity: 0;
  padding: 15px 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;

  &.active {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-name: ${fadeInDown};
    transition-timing-function: ease-out;
    transform: translate3d(0, -50px, 0);
    opacity: 1;
  }

  @media ${device.laptop} {
    font-size: 52px;
    left: 0;
    text-align: left;
  }
`;

const Subtitle = styled(SectionSubtitle)`
  margin: 30px 0;
  padding-top: 15px;

  @media ${device.laptop} {
    font-size: 22px;
    margin: 0 0 9vh 0;
    text-align: left;
  }
`;

const ButtonInvest = styled(Flex).attrs({
  as: 'a',
  align: 'center',
  justify: 'center',
})`
  background: ${color.primary};
  border-radius: 4px;
  color: ${color.light};
  cursor: pointer;
  font-family: var(--bold-font);
  font-size: 18px;
  height: 50px;
  margin-bottom: 50px;
  padding: 0 20px;
  text-transform: uppercase;
  max-width: 100%;

  &:hover {
    color: ${color.light};
  }

  @media ${device.laptop} {
    margin-bottom: 9vh;
    font-size: 25px;
    margin-bottom: 44px;
    padding-top: 6px;
    width: 312px;
    height: 61px;
  }
`;

const AnimationWrapper = styled(Flex)`
  justify-content: center;
  margin-top: 50px;
  padding-top: 30px;
  position: relative;
  width: 100%;

  @media ${device.laptop} {
    justify-content: flex-end;
    padding-left: 50px;
    width: 50%;
  }

  @media ${device.laptopL} {
    width: 40%;
  }
`;

const Illustration = styled(Image)`
  display: none;

  @media ${device.tablet} {
    display: block;
    height: 300px;
    left: -230px;
    position: absolute;
    right: auto;
    top: 70px;
  }

  @media ${device.laptop} {
    height: 380px;
  }

  @media ${device.laptopL} {
    height: 420px;
    right: 0;
    top: 120px;
    left: -275px;
  }
`;

const StoreImage = styled(Image)<{ width: number }>`
  width: 115px;

  &:first-child {
    margin-right: 20px;
  }

  @media ${device.tablet} {
    width: ${({ width = 204 }) => width}px;
  }
`;

export {
  Title,
  AnimatedSubtitle,
  Subtitle,
  SubtitleWrapper,
  ButtonInvest,
  TitleWrapper,
  HeaderWrapper,
  AnimationWrapper,
  Illustration,
  StoreImage,
};
